::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 0.075rem;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
}

.btn {
    white-space: nowrap;
}

a {
    color: var(--info);
    text-decoration: none;
}

a:hover,
a:focus {
    color: var(--cyan);
}

pre {
    white-space: pre-wrap;
}

.max-w-400 {
    width: 100%;
    max-width: 400px;
}

.text-transparent {
    color: transparent !important;
}

.page-center {
    -ms-flex-align: center;
    align-items: center;
    /* background-color: #e9ecef; */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: calc(100vh - 115px);
}

.brand-image.mini {
    display: none;
}

.sidebar-collapse .brand-image {
    display: none;
}

.sidebar-collapse .brand-image.mini {
    display: inline-block;
}

.main-sidebar:hover .brand-image {
    display: inline-block;
}

.main-sidebar:hover .brand-image.mini {
    display: none;
}

/*avatar-wrapper*/
.avatar-wrapper {
    position: relative;
    height: 200px;
    width: 200px;
    margin: 50px auto;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px 15px -5px black;
    transition: all 0.3s ease;
}

.avatar-wrapper:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.avatar-wrapper:hover .profile-pic {
    opacity: 0.5;
}

.avatar-wrapper .profile-pic {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
}

.avatar-wrapper .profile-pic:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 190px;
    background: #ecf0f1;
    color: #34495e;
    text-align: center;
}

.avatar-wrapper .upload-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.avatar-wrapper .upload-button i {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease;
    font-size: 44px;
    line-height: 200px;
    background-color: #EDEBE1;
    color: #fff;
}

.avatar-wrapper .upload-button:hover i {
    opacity: 0.9;
}

.card-header>.card-tools .input-group,
.card-header>.card-tools .nav,
.card-header>.card-tools .pagination {
    margin-bottom: 0;
    margin-top: 0;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 400;
}

.h5,
h5 {
    font-weight: 400;
}

.navbar-light .navbar-nav .nav-link {
    color: #664e04;
    white-space: nowrap;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: var(--info);
}

.form-label,
.form-check {
    color: var(--info);
}

.form-check-input:checked {
    background-color: var(--info);
    border-color: var(--info);
}

.page-item.active .page-link {
    background-color: var(--info);
    border-color: var(--info);
}

.page-link {
    color: var(--info);
}

.nav-tabs .nav-link {
    color: var(--info);
}

.nav-tabs .nav-link:hover {
    color: var(--info);
}

.form-check-inline .form-check-input {
    margin-top: .25em;
}

.accordion-button:not(.collapsed) {
    color: var(--info);
    background-color: var(--bs-accordion-bg);
}

.table-noborder th {
    border-color: transparent;
}

.table-noborder thead th {
    color: var(--info);
    border-color: transparent;
    font-weight: 400;
}

.table-noborder tr {
    border-color: transparent;
}

:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.nav-sidebar>.nav-header hr {
    margin: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--info);
}

.nav-pills .nav-link:not(.active):hover {
    color: var(--info);
}

tr.level-1 {
    font-weight: 600;
}

tr.level-2 {
    font-weight: 600;
    font-style: italic;
}

tr.level-2 td:first-child {
    padding-left: 30px;
}

tr.level-3 td:first-child {
    padding-left: 60px;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
    --bs-offcanvas-width: 600px;
}

.calendarWrap {
    display: inline-block;
    position: relative;
}

.calendarWrap input:read-only {
    background-color: #fff;
    width: max-content;
}

.calendarWrap .calendarElement {
    position: absolute;
    left: 0;
    border: solid 1px #ccc;
    z-index: 9999;
}

canvas.chartjs-box {
    border: solid 1px #ccc;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
}

.tab-content {
    border: solid 1px #ddd;
    margin-top: -1px;
    border-radius: 0px 0px 7px 7px;
    border-top: none;
    margin-bottom: 1rem !important;
}

.pac-container {
    z-index: 1055 !important;
}

.form-check-label {
    font-size: 14px;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
}

.rtl {
    direction: rtl;
}

.shadow-0 {
    box-shadow: none;
    padding-top: 10px;
    margin-top: 1px !important;
}

.welcome {
    background-image: linear-gradient(-90deg, #adfbda 0, #35c3ff 30%, #fda399 50%, #76d880 70%, #ebf38b 90%, #adfbda 100%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    /* filter: drop-shadow(0 0 2rem #000);
    text-shadow: none !important; */
}

.table-striped tbody tr hr {
    margin: 0;
    color: #dee2ed;
}

.table-striped tbody tr:nth-of-type(odd) hr {
    color: #fff;
}

/*fieldset*/
fieldset {
    padding: 0 1rem 1rem;
    border: solid 1px #dee2ed;
    background-color: #fafafa;
    border-radius: .375rem;
}

fieldset legend {
    font-weight: 400;
    color: var(--info);
    font-size: initial;
    float: none;
    display: inline-block;
    width: auto;
    padding: 0.5rem;
    margin: 0;
}

.modal-title {
    font-weight: 400;
    font-size: 1.3rem;
}

.modal-title span {
    color: #9E9E9E;
}

/*sort-by*/
.sort-by .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
}

.sort-by .dropdown-menu .dropdown-item.active {
    background-color: transparent !important;
    color: initial;
}

.sort-by .dropdown-item i {
    font-size: 8px;
    color: transparent;
    margin-right: 5px;
}

.sort-by .dropdown-item.active i {
    color: initial;
}

/*seats*/
.seats {
    overflow: auto;
}

.seats ul.seat-row {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.seats ul.seat-row li {
    flex: 0 0 46px;
    border: solid 1px #ddd;
    border-radius: .375rem;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin: 2px;
    cursor: pointer;
}

.seats ul.seat-row li:hover {
    border: solid 1px #fff;
}

.seats.xs ul.seat-row li {
    flex: 0 0 24px;
    border-radius: .275rem;
    height: 22px;
    line-height: 22px;
    margin: 1px;
    font-size: 10px;
}

/*event-seats*/
.event-seats {
    border: solid 1px #ced4da;
    border-radius: 0.375rem;
    padding: 0.375rem 0.375rem 0 0.375rem;
}

.event-seats-inner {
    overflow: auto;
    padding-bottom: 0.375rem;
    padding-top: 0.1rem;
}

.event-seats .seat-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    margin-bottom: 2px;
}

.event-seats .seat-row .seat {
    display: flex;
    flex: 0 0 0;
    padding: 2px 3px;
    position: relative;
}

.event-seats .seat-row .seat label {
    border-radius: 4px;
    border: 1px solid var(--color);
    padding: 0;
    width: 32px;
    height: 32px;
    line-height: 30px;
    margin-bottom: 0;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    color: var(--color);
}

.event-seats .seat-row .seat:not(.ordered):hover input[type="checkbox"]:not(:disabled)+label,
.event-seats .seat-row .seat:not(.unserved):hover input[type="checkbox"]:not(:disabled)+label {
    /* border-color: #333 !important;
    color: #333 !important;
    background-color: #0dcaf0 !important; */
    scale: 1.2;
}

.event-seats .seat-row .seat input[type="checkbox"] {
    display: none !important;
}

.event-seats .seat-row .seat input[type="checkbox"]:checked+label {
    border-color: #333 !important;
    color: #333 !important;
    background-color: #0dcaf0 !important;
    position: relative;
}

/* .event-seats .seat-row .seat input[type="checkbox"]:checked+label:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 24px;
    color: var(--color);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
} */

.event-seats .seat-row .seat input[type="checkbox"]:disabled+label {
    cursor: not-allowed;
    background-color: #ced4da;
    color: #aaa;
    border-color: #ced4da;
}

.event-seats .seat-row .seat.unserved label {
    cursor: not-allowed;
}

.date-button,
.time-button {
    border: 1px solid #ced4da;
    border-radius: .375rem !important;
    margin: 0 10px 0 0;
}

.date-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-button span:nth-child(2) {
    font-size: 26px;
    font-weight: 600;
}

.btn-check:checked+.date-button,
.btn-check:checked+.time-button {
    background-color: #0dcaf0;
    border-radius: .375rem !important;
}

/*small*/
.event-seats.small .seat-row {
    margin-bottom: 0;
}

.event-seats.small .seat-row .seat {
    padding: 2px;
}

.event-seats.small .seat-row .seat label {
    width: 26px;
    height: 26px;
    line-height: 24px;
    font-size: 11px;
}

/*large*/
.event-seats.large .seat-row {
    margin-bottom: 0;
}

.event-seats.large .seat-row .seat {
    padding: 4px;
}

.event-seats.large .seat-row .seat label {
    width: 38px;
    height: 38px;
    line-height: 36px;
    font-size: 16px;
}

/*ticket-details*/
.ticket-details {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}

.ticket-details dt {
    width: 100px;
    font-weight: 500;
}

.ticket-details dd {
    width: calc(100% - 100px);
}

.ticket-details.dt-200 dt {
    width: 200px;
}

.ticket-details.dt-200 dd {
    width: calc(100% - 200px);
}

/*react-dropdown-tree-select*/
.react-dropdown-tree-select .dropdown {
    width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger>span:after {
    font-size: 12px;
    color: #555;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger .tag-list .tag-item {
    margin: 2px;
    font-size: 14px;
    color: initial;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger .tag-list .tag-item .placeholder {
    color: initial;
    background-color: transparent;
    cursor: pointer;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    max-height: 300px;
    min-width: 100%;
    overflow: auto;
    font-size: 14px;
}

.react-dropdown-tree-select .dropdown .dropdown-content .root {
    padding: 0px;
    margin: 0px;
}

.react-dropdown-tree-select .dropdown .dropdown-content .node label {
    margin-bottom: 0;
}

/*react-datepicker*/
.react-datepicker {
    border: 1px solid #ccc;
    color: #666;
}

.react-datepicker__header {
    background-color: rgb(239, 242, 247);
    border-bottom: 1px solid #ccc;
}

.react-datepicker__navigation {
    top: 8px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #666;
    font-weight: 500;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: rgba(61, 145, 255, 0.8);
    color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: rgb(61, 145, 255);
    color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: rgba(61, 145, 255, 0.8);
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    padding-top: 3px;
    padding-bottom: 3px;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: rgba(61, 145, 255, 0.8);
}

.react-datepicker-wrapper {
    width: 160px;
}

.react-datepicker-wrapper+.react-datepicker__tab-loop {
    display: inline-block;
}

/*list-in-table*/
ul.list-in-table {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.list-in-table i {
    color: #bbb;
    min-width: 25px;
    text-align: center;
    font-size: 14px;
}

ul.list-in-table span {
    color: #aaa;
    margin-right: 5px;
    white-space: nowrap;
}

ul.list-in-table li {
    display: flex;
    align-items: baseline;
    line-height: 1.2;
    margin-bottom: 5px;
}

/*w3 animate*/
.w3-spin {
    animation: w3-spin 2s infinite linear
}

@keyframes w3-spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(359deg)
    }
}

.w3-animate-fading {
    animation: fading 10s infinite
}

@keyframes fading {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.w3-animate-opacity {
    animation: opac 0.8s
}

@keyframes opac {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.w3-animate-top {
    position: relative;
    animation: animatetop 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.w3-animate-left {
    position: relative;
    animation: animateleft 0.4s
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

.w3-animate-right {
    position: relative;
    animation: animateright 0.4s
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

.w3-animate-bottom {
    position: relative;
    animation: animatebottom 0.4s
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.w3-animate-zoom {
    animation: animatezoom 0.6s
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.w3-animate-input {
    transition: width 0.4s ease-in-out
}

.w3-animate-input:focus {
    width: 100% !important
}

/*draft*/
.draft-wrapper {
    border: 1px solid #ced4da;
    border-radius: .375rem;
}

.draft-toolbar {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    background-color: transparent;
}

.draft-editor {
    padding: 0 .75rem;
    max-height: 400px;
    min-height: 200px;
}

.rdw-embedded-modal,
.rdw-link-modal {
    height: auto;
}

.react-tel-input .form-control {
    width: 100%;
}

.btn-actions-dropdown .dropdown-menu i {
    min-width: 20px !important;
    text-align: center;
}

.table-ticket-sellers-details {
    border: solid 1px #dee2e6;
}

.table-ticket-sellers-details th,
.table-ticket-sellers-details td {
    border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-top: 0;
    border-bottom: 0;
}

.summary-box {
    white-space: nowrap;
    text-align: left;
    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 15px;
}

.summary-box h6 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
}

.summary-box h3 {
    margin: 0;
}

.summary-box p {
    margin-bottom: 0;
}

@media (max-width: 480px) {
    .card-header .btn {
        font-size: 0;
    }

    .card-header .btn>i {
        font-size: initial;
    }

    .navbar-nav .nav-link {
        font-size: 0;
    }

    .navbar-nav .nav-link>i {
        font-size: initial;
    }
}